import { vacancyApiActions } from '@app/features/vacancy/store/actions/vacancy-api.actions';
import { accountTypeaheadActions, legalEntityGuardActions } from '@app/store/actions';
import { Account } from '@mkp/account/data-access';
import { accountApiActions } from '@mkp/account/data-access/actions';
import { authActions } from '@mkp/auth/actions';
import { claimManagementActions, legalEntityDetailsActions } from '@mkp/legal-entity/actions';
import { FetchState, LoadingState } from '@mkp/shared/util-state';
import { BoDashboardActions } from '@mkp/user/feature-bo-dashboard/actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { boAuthActions } from '@mkp/auth/actions';

interface State extends EntityState<Account> {
  fetchState: FetchState<{ message: string }>;
  selectedAccountId: string | undefined;
}

const adapter: EntityAdapter<Account> = createEntityAdapter<Account>();

const initialState: State = adapter.getInitialState({
  fetchState: LoadingState.INIT,
  selectedAccountId: undefined,
  accountMembershipCount: undefined,
});

const reducer = createReducer(
  initialState,
  on(accountApiActions.addAccountSuccess, (state) => ({
    ...state,
    fetchState: LoadingState.LOADED,
  })),
  on(accountApiActions.addAccountFailure, (state, { error }) => ({
    ...state,
    fetchState: { error },
  })),
  // these actions set the state to LOADING because they trigger a call in AccountResource
  on(
    authActions.loadActiveAccountMembershipsSuccess,
    legalEntityGuardActions.canActivate,
    vacancyApiActions.listVacanciesSuccess,
    vacancyApiActions.loadMoreVacanciesSuccess,
    claimManagementActions.loadAccountFromSelectedLegalEntity,
    legalEntityDetailsActions.pageEntered,
    BoDashboardActions.enter,
    BoDashboardActions.initDropdown,
    vacancyApiActions.loadVacanciesByIdsSuccess,
    boAuthActions.loadWorkspace,
    accountApiActions.loadUserAccounts,
    accountApiActions.addAccount,
    accountApiActions.archiveAccount,
    (state) => ({
      ...state,
      fetchState: LoadingState.LOADING,
    })
  ),
  on(authActions.workspaceSelected, (state, { accountId }) => ({
    ...state,
    selectedAccountId: accountId,
  })),
  on(
    authActions.loadAccountsAfterLoginSuccess,
    boAuthActions.loadWorkspaceAccountLoadSuccess,
    (state, { accounts }) =>
      adapter.upsertMany(accounts, {
        ...state,
        selectedAccountId: accounts.length === 1 ? accounts[0].id : undefined,
        fetchState: LoadingState.LOADED,
      })
  ),
  // these actions save an account in store and select an account at the same time
  on(
    accountApiActions.loadAccountByLegalEntityExternalIdSuccess,
    accountTypeaheadActions.selectAccount,
    (state, { account, selectedAccountId }) =>
      adapter.upsertOne(account, { ...state, selectedAccountId, fetchState: LoadingState.LOADED })
  ),
  // these actions save an account in store
  on(
    accountApiActions.loadAccountSuccess,
    accountApiActions.archiveAccountSuccess,
    (state, { account }) =>
      adapter.upsertOne(account, { ...state, fetchState: LoadingState.LOADED })
  ),
  // these action save multiple accounts in store
  on(
    accountApiActions.loadAccountsSuccess,
    accountApiActions.loadUserAccountsSuccess,
    (state, { accounts }) =>
      adapter.upsertMany(accounts, { ...state, fetchState: LoadingState.LOADED })
  ),
  // reset accounts on legal entity selection
  on(legalEntityDetailsActions.accountsLoadSuccess, (state, { accounts }) =>
    adapter.setAll(accounts, { ...state, fetchState: LoadingState.LOADED })
  ),
  // these actions unselect an account
  on(accountTypeaheadActions.unselectAccount, boAuthActions.workspaceUnselected, (state) => ({
    ...state,
    selectedAccountId: undefined,
  })),
  // these actions set the state to ERROR
  on(
    authActions.loadAccountsAfterLoginFailed,
    boAuthActions.loadWorkspaceAccountLoadError,
    accountApiActions.loadAccountFailure,
    accountApiActions.loadAccountsFailure,
    accountApiActions.loadAccountByLegalEntityExternalIdFailure,
    accountApiActions.archiveAccountFailure,
    accountApiActions.addAccountFailure,
    (state, { error }) => ({
      ...state,
      fetchState: { error },
    })
  )
);

const feature = createFeature({
  name: 'account',
  reducer,
});

const { selectAll } = adapter.getSelectors(feature.selectAccountState);

export const accountFeature = { ...feature, selectAll };
export { State as AccountState };
